import request from '@/utils/request'
//订单一览查询
export function listPage(query) {
    return request({
      url: '/order/listPage',
      method: 'get',
      params:query
    })
  }
//订单新增
export function save(data) {
    return request({
      url: '/order/save',
      method: 'post',
     data
    })
  }
//订单进度分页查询
export function orderScheduleListPage(query) {
    return request({
      url: '/order/orderScheduleListPage',
      method: 'get',
      params:query
    })
  }
  //根据code值查询
  export function getCode(query) {
    return request({
      url: '/code/getCode',
      method: 'get',
      params:query
    })
  }
//生成订单编号
export function orderNumber(query) {
    return request({
      url: '/order/orderNumber',
      method: 'get',
      params:query
    })
  }
//查询公司名称
export function orderCompanyResults(query) {
    return request({
      url: '/order/orderCompanyResults',
      method: 'get',
      params:query
    })
  }
//订单详情
export function listById(query) {
    return request({
      url: '/order/listById',
      method: 'get',
      params:query
    })
  }
//订单进度新增
export function OrderScheduleSave(data) {
    return request({
      url: '/order/OrderScheduleSave',
      method: 'post',
     data
    })
  }
//退款
export function refund(query) {
  return request({
    url: '/payOrder/refund',
    method: 'get',
    params:query
  })
}
//人才、艾媒订单终止
export function orderNotTermination(data) {
  return request({
    url: '/order/orderNotTermination',
    method: 'post',
   data
  })
}

// 选项 /code/getCodeMaster
export function getCodeMaster(query) {
  return request({
    url: '/code/getCodeMaster',
    method: 'get',
    params:query
  })
}

// 导出订单数据
export function exportOrder(data) {
  return request({
    url: "/order/exportOrder",
    method: "post",
    responseType: "blob",
    data
  })
}
